import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const CTA = ({ label, onClick, withBackgroundColor, sx, loading, ...rest }) => {
	const theme = useTheme();

	const isDarkMode = useIsDarkMode();

	return (
		<>
			<Box
				role="button"
				tabIndex="0"
				onClick={onClick}
				sx={{
					p: 1,
					textAlign: 'center',
					position: 'relative',
					minWidth: '150px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: 0,
					backgroundColor: theme.palette.vMaroon.main,
					clipPath: 'polygon(15% 0, 95% 0, 100% 30%, 85% 100%, 5% 100%, 0 70%)',
					cursor: loading ? 'not-allowed' : 'pointer',
					pointerEvents: 'auto',
					zIndex: 2,
					...sx,
					'&:hover, &:focus': {
						'&::after': {
							backgroundColor: withBackgroundColor
								? theme.palette.vMaroon.dark
								: theme.palette.vMaroon.light
						}
					},

					'&::after': {
						content: '""',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '98%',
						height: '98%',
						backgroundColor: withBackgroundColor
							? theme.palette.vMaroon.main
							: isDarkMode
							? '#272727'
							: '#FFFFFF',
						clipPath: 'polygon(15% 0, 95% 0, 100% 30%, 85% 100%, 5% 100%, 0 70%)'
					}
				}}
				{...rest}>
				<Typography
					sx={{
						fontWeight: 600,
						zIndex: 2,
						color: withBackgroundColor ? '#FFFFFF' : theme.palette.vMaroon.main
					}}>
					{label}
				</Typography>
			</Box>
		</>
	);
};

export default CTA;
